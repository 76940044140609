<template>
  <Toast />
  <ConfirmDialog />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >INVENTARIO</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">MOVIMIENTOS</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <span style="border-bottom: 1px solid #005f91; font-size: 1.5rem">
        <strong>Registrar Movimientos de Inventario (Ingreso/Egreso)</strong>
      </span>
    </div>
    <div class="col-12 p-fondo">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <span><strong>SELECCIONE SUCURSAL:</strong></span>
          <Dropdown
            v-model="sucursalSelected"
            :options="sucursales"
            optionLabel="nombre"
            optionValue="id"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>TIPO MOVIMIENTO:</strong></span>
          <Dropdown
            v-model="tipoMovimientoSelected"
            :options="tipoMovimientos"
            optionLabel="nombre"
            placeholder="Seleccione Tipo de Movimiento"
          >
            <template #option="slotProps">
              <div>
                {{ slotProps.option.nombre }} -
                {{ slotProps.option.tipo }}
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="field col-12 md:col-6">
          <span
            ><strong
              >BUSCAR PRODUCTO POR NOMBRE | DESCRIPCI&Oacute;N:</strong
            ></span
          >
          <AutoComplete
            :dropdown="true"
            field="descripcion"
            v-model="productoSelected"
            :suggestions="productosListadoFiltrado"
            @complete="buscarProducto($event)"
            placeholder="Buscar Producto"
            @item-select="agregarProductoDetalle"
            :disabled="tipoMovimientoSelected.id == null"
          >
            <template #item="slotProps">
              <div>
                {{ slotProps.item.descripcion }} **
                {{ slotProps.item.fabrica_nombre }} **
                <span
                  :class="
                    stockClass(
                      slotProps.item.stock.length > 0
                        ? slotProps.item.stock[0].cantidad
                        : 0
                    )
                  "
                  ><strong>{{
                    slotProps.item.stock.length > 0
                      ? slotProps.item.stock[0].cantidad
                      : 0
                  }}</strong></span
                >
              </div>
            </template>
          </AutoComplete>
          <small class="p-invalid" v-if="errors.productoSelected">{{
            errors.productoSelected[0]
          }}</small>
        </div>
        <!--  <div class="field col-12 md:col-2">
          <label for="fecha_movimiento"><strong>ACCIONES:</strong></label>
          <Button
            label="AGREGAR"
            icon="pi pi-plus"
            class="p-button-primary"
            v-tooltip.top="'Agregar el Producto al detalle'"
            @click="agregarProductoDetalle"
          />
        </div> -->
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="reporte_stock"
        key="id"
        :value="detalle_movalmacen"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm"
        responsive="true"
        editMode="cell"
        stripedRows
        showGridlines
        @cell-edit-complete="guardandoCambiosCelda"
      >
        <template #loading
          ><div class="flex align-items-center justify-content-center">
            <ProgressSpinner /></div
        ></template>
        <template #empty>
          <div class="flex align-items-center justify-content-center">
            <div class="p-invalid">No existen Productos en el detalle!</div>
          </div>
        </template>
        <Column field="id" header="CODIGO"></Column>
        <Column field="producto" header="DETALLE/PRODUCTO"></Column>
        <Column field="stock_actual" header="STOCK ACTUAL">
          <template #body="slotProps">
            <span :class="stockClass(slotProps.data.stock_actual)"
              ><strong>{{ slotProps.data.stock_actual }}</strong></span
            >
          </template>
        </Column>
        <Column
          class="text-center bg-green-200"
          field="cantidad"
          header="CANTIDAD"
        >
          <template #body="slotProps">
            {{ slotProps.data.cantidad }}
          </template>
          <template #editor="slotProps">
            <InputNumber
              :inputClass="'text-right'"
              :min="0"
              :minFractionDigits="0"
              locale="de-DE"
              v-model="slotProps.data.cantidad"
            />
          </template>
        </Column>
        <Column field="precio_compra" header="PUCompra">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.precio_compra) }}
          </template>
        </Column>
        <Column field="precio_sugerido" header="PUVentas">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.precio_sugerido) }}
          </template>
        </Column>
        <Column field="subtotal" header="SubTotalCompra">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.cantidad * slotProps.data.precio_compra
              )
            }}
          </template>
        </Column>
        <Column field="subtotal" header="SubTotalVenta">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.cantidad * slotProps.data.precio_sugerido
              )
            }}
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              @click="quitarProductoDetalle(slotProps)"
              v-tooltip.top="'Quitar Producto'"
              icon="pi pi-times"
              class="p-button-rounded p-button-danger p-button-text"
            />
          </template>
        </Column>
        <template #footer>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span
              ><strong>TOTAL COMPRAS: </strong>
              {{ convertirNumeroGermanicFormat(totalCompras) }}</span
            >
            <span class="ml-2"
              ><strong>TOTAL VENTAS: </strong>
              {{ convertirNumeroGermanicFormat(totalVentas) }}</span
            >
          </div>
        </template>
      </DataTable>
    </div>
    <div class="p-fluid formgrid grid mt-4">
      <div class="field col-12 md:col-9">
        <span><strong>OBERVACIONES:</strong></span>
        <Textarea v-model="observacion" required="true" rows="1" autoResize>
        </Textarea>
      </div>
      <div class="field col-12 md:col-3">
        <span><strong>ACCIONES:</strong></span>
        <Button
          label="GUARDAR MOVIMIENTO"
          icon="pi pi-save"
          class="p-button-info p-button-lg"
          v-tooltip.top="'Registrar Movimiento'"
          @click="guardarMovimiento"
        />
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="tipo_impresionModal"
    :style="{ width: '350px' }"
    header="Tipo de Impresión"
    :modal="true"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <span><strong>SELECCIONE EL TIPO DE IMPRESI&Oacute;N: </strong></span>
          <Dropdown
            v-model="tipo_impresionSelect"
            :options="tipos_impresion"
            optionLabel="nombre"
            optionValue="id"
          />
        </div>
        <div class="field col-12 md:col-12 mt-4">
          <div class="p-inputgroup flex justify-content-end">
            <Button
              label="NO IMPRIMIR"
              icon="pi pi-times"
              class="p-button-danger p-button-lg mr-1"
              @click="printAction()"
            />
            <Button
              label="ACEPTAR"
              icon="pi pi-save"
              class="p-button-info p-button-lg"
              @click="tipo_impresionModal = false"
            />
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script>
import SucursalService from "@/service/SucursalService";
import ProductService from "@/service/ProductService";
import TipoMovimientosService from "@/service/TipoMovimientos";

export default {
  data() {
    return {
      detalle_movalmacen: [],
      sucursalSelected: 1,
      sucursales: [],
      tipoMovimientos: [],
      tipoMovimientoSelected: 1,
      productoSelected: null,
      productosListadoFiltrado: [],
      fecha_movimiento: new Date(),
      observacion: null,
      enviando: false,
      errors: {},
      stockClass: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      tipo_impresionSelect: 1, // 1=Carta, 2=Ticket
      tipo_impresionModal: false,
      tipos_impresion: [
        { id: 1, nombre: "CARTA" },
        { id: 2, nombre: "TICKET" },
      ],
      id_mov: null,
      si_imprimir: false,
    };
  },
  computed: {
    totalCompras() {
      let total = 0;
      this.detalle_movalmacen.forEach((element) => {
        total += element.cantidad * element.precio_compra;
      });
      return total;
    },
    totalVentas() {
      let total = 0;
      this.detalle_movalmacen.forEach((element) => {
        total += element.cantidad * element.precio_sugerido;
      });
      return total;
    },
  },
  sucursalService: null,
  productService: null,
  tipoMovimientosService: null,
  created() {
    this.sucursalService = new SucursalService();
    this.productService = new ProductService();
    this.tipoMovimientosService = new TipoMovimientosService();
  },
  mounted() {
    this.cargarSucursales();
    this.cargarTipoMovimientos();
  },
  methods: {
    printAction() {
      this.tipo_impresionModal = false;
      this.si_imprimir = false;
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
    validarFormulario() {
      if (this.sucursalSelected == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar una sucursal",
          life: 3000,
        });
        return false;
      }
      if (this.tipoMovimientoSelected.id == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar un tipo de movimiento",
          life: 3000,
        });
        return false;
      }
      if (this.detalle_movalmacen.length == 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe agregar al menos un producto al detalle",
          life: 3000,
        });
        return false;
      }
      return true;
    },
    guardarMovimiento() {
      if (this.validarFormulario()) {
        this.enviando = true;
        this.tipo_impresionModal = true;
        let datos = {
          sucursal_id: this.sucursalSelected,
          tipo_movalmacen_id: this.tipoMovimientoSelected.id,
          tipo_movimiento: this.tipoMovimientoSelected.tipo,
          fecha_movimiento: this.fecha_movimiento,
          observacion: this.observacion,
          productos: this.detalle_movalmacen,
        };
        this.$confirm.require({
          header: "Confirmación",
          message: "¿ESTÁS SEGURO DE GUARDAR EL MOVIMIENTO?",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Si, Guardar",
          rejectLabel: "No, Cancelar",
          acceptClass: "p-button-primary p-button-lg",
          rejectClass: "p-button-danger p-button-lg",
          acceptIcon: "pi pi-save",
          rejectIcon: "pi pi-times",
          discard: () => {
            this.guardando = false;
          },
          accept: () => {
            this.tipoMovimientosService
              .saveMovimientoAlmacen(datos)
              .then((data) => {
                this.id_mov = data.mov_almacen.id;
                if (data.status == 200) {
                  this.$toast.add({
                    severity: "success",
                    summary: "Movimiento de Almacen",
                    detail: data.mensaje,
                    life: 3000,
                  });
                  this.enviando = false;
                  this.detalle_movalmacen = [];
                  this.observacion = "";
                  if (this.si_imprimir) {
                    this.imprimirMovimiento();
                  }
                  this.tipo_impresionSelect = 1;
                  this.id_mov = null;
                } else {
                  this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: data.mensaje,
                    life: 3000,
                  });
                  this.enviando = false;
                  this.tipo_impresionModal = false;
                  this.id_mov = null;
                }
              });
          },
          reject: () => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "No se guardo el movimiento",
              life: 3000,
            });
            this.enviando = false;
          },
        });
      }
    },
    imprimirMovimiento() {
      let datos = {
        tipo_impresion: this.tipo_impresionSelect,
        movimiento_id: this.id_mov,
      };
      this.tipoMovimientosService
        .imprimirMov(datos)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    guardandoCambiosCelda(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;

      if (
        this.tipoMovimientoSelected.nombre == "OTROS INGRESOS PRODUCTOS" ||
        this.tipoMovimientoSelected.nombre == "AJUSTE DE INVENTARIO (INGRESO)"
      ) {
        if (newValue <= 0) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "La cantidad no puede ser menor a 0",
            life: 3000,
          });

          data.cantidad = 1;
          return;
        }
      } else {
        if (newValue > data.stock_actual || newValue <= 0) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "La cantidad no puede ser mayor al stock actual",
            life: 3000,
          });

          data.cantidad = parseFloat(data.stock_actual);
          return;
        }
      }
    },
    agregarProductoDetalle() {
      if (this.tipoMovimientoSelected.id == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar un tipo de movimiento",
          life: 3000,
        });
        return;
      }
      if (this.productoSelected == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar un producto",
          life: 3000,
        });
        return;
      }
      //Verificar si el producto ya esta en el detalle
      let existe = this.detalle_movalmacen.find((item) => {
        return item.id == this.productoSelected.id;
      });

      if (existe) {
        this.errors.productoSelect = ["El producto ya esta en el detalle"];
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El producto ya esta en el detalle",
          life: 3000,
        });
        this.enviando = false;
        return false;
      }

      if (this.cantidad <= 0 || this.cantidad === null) {
        this.errors.cantidad = ["La cantidad debe ser mayor a 0"];
        return false;
      }
      if (
        this.productoSelected.stock[0].cantidad == 0 &&
        this.tipoMovimientoSelected.nombre != "OTROS INGRESOS PRODUCTOS" &&
        this.tipoMovimientoSelected.nombre != "AJUSTE DE INVENTARIO (INGRESO)"
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "La cantidad no puede ser mayor al stock actual",
          life: 3000,
        });
        return false;
      }

      let producto = this.productoSelected;
      let cantidad = 1;
      let detalle = {
        id: producto.id,
        producto: producto.descripcion,
        stock_actual: producto.stock[0].cantidad || 0,
        cantidad: cantidad,
        /* precio_compra: producto.precio_compra,
        precio_sugerido: producto.precio_sugerido,
        subtotal: cantidad * producto.precio_sugerido, */
        precio_compra: producto.stock[0].cpp || 0,
        precio_sugerido: producto.stock[0].ultimo_precio || 0,
        subtotal: cantidad * producto.stock[0].ultimo_precio || 0,
      };

      this.detalle_movalmacen.push(detalle);
      this.productoSelected = null;
    },
    buscarProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXNombre({
            texto: event.query,
            sucursal_id: this.sucursalSelected,
          })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          response.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarTipoMovimientos() {
      this.tipoMovimientosService
        .getTipoMovimientosAll()
        .then((response) => {
          response.forEach((tipoMovimiento) => {
            this.tipoMovimientos.push(tipoMovimiento);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    quitarProductoDetalle(datos) {
      this.$confirm.require({
        header: "Confirmación",
        message: "¿ESTÁS SEGURO DE QUITAR EL PRODUCTO DEL DETALLE?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, Quitar",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        discard: () => {
          this.guardando = false;
        },
        accept: () => {
          this.detalle_movalmacen.splice(datos.index, 1);
          this.$toast.add({
            severity: "success",
            summary: "Movimiento de Almacen",
            detail: "Se quito el producto del detalle",
            life: 3000,
          });
        },
        reject: () => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "No se quito el producto del detalle",
            life: 3000,
          });
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
  border-radius: 5px;
  border: 1px solid #002236;
  padding: 10px;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
</style>
